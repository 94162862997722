.bar {
    position: fixed;
    background-color: #0e8388;
    width: 200px;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    z-index: 10;
  }
  
  .socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .icon {
    height: auto;
    width: 20%;
    margin: 15px;
    color: white;
  }

  @media screen and (max-width: 1200px) {

    .bar {
      width: 100%;
      height: fit-content;
    }
  
    .socials {
      flex-direction: row;
      justify-content: flex-end;
      margin-right: 50px;
    }
    .icon {
      width: 5%;
    }
  }

  @media screen and (max-width: 680px) {
    .socials {
      justify-content: space-around;
      margin: 0;
    }
  }