.dimBackground {
    position: absolute;
    height: 200vh;
    width: 100%;
    background-color: #00000090;
    z-index: 200;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.formWrapper {
    position: relative;
    z-index: 200;
    min-height: fit-content;
    width: 90%;
    margin: 20px;
    margin-top: 5%;
    border-radius: 12px;
    background-color: #CBE4DE;
    display: flex;
    justify-content: space-between;
    
}

.logoWrapper {
    background-color: #2E4F4F;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* height: 300px; */
    width: 20%;
    height: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoWrapper img {
    height: auto;
    width: 100%;
}

.form {
    margin-bottom: 100px;
}

.form input, .form textarea {
    width: 80%;
    margin: 15px;
    border-radius: 5px;
    border: none;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    background-color: white;
}

.form input {
    height: 6%;
}

.form input::placeholder, .form textarea::placeholder {
    padding: 10px;
}

.form h1 {
    color: #2E4F4F;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 50px;
}

@media screen and (max-width: 1200px) {

    .formWrapper {
        width: 75%;
        margin-top: 10%;
        flex-direction: column-reverse;
    }

    .logoWrapper {
        width: fit-content;
        border-bottom-right-radius: 0;
        border-top-left-radius: 12px;
    }

    .logoWrapper img {
        width: 30%;
        height: auto;
    }
}

@media screen and (max-width: 800px) {
 
    .formWrapper {
        margin-top: 15%;
        width: 90%;
    }
    .form input, .form textarea {
        margin: 5px;
    }
    .form h1 {
        font-size: 22px;
    }
}

@media screen and (max-width: 350px) {
    .formWrapper {
        margin-top: 30%;
    }

    .form input::placeholder, .form textarea::placeholder {
        font-size: 12px;
    }

    .logoWrapper img {
        width: 50%;
    }

    .form h1 {
        font-size: 18px;
    }
}

@media screen and (max-width: 275px) {
    .formWrapper button {
        width: 80%;
    }
}